import React, { useEffect, useRef, useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import {
  changeBase64ToBlob,
  createFormDataWithImage,
} from "@earlypay/shared/utils";
import { applicationState } from "@recoil/applications/atoms";
import Webcam from "react-webcam";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Image,
  ImageUploader,
  PageContainer,
  Text,
  VStack,
  useToast,
} from "@earlybird/ui";

import { Header } from "@components/layouts";
import IdentificationCameraView from "@components/organisms/IdentificationCameraView";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import { useUpdateIdentification } from "@apis/hooks";

import Meta from "../../../components/layouts/Meta";

const Identification = () => {
  const { navigateWithParams } = useNavigateWithParams();
  const application = useRecoilValue(applicationState);
  const mutation = useUpdateIdentification(application.id);
  const toast = useToast();

  const [visibleImageUploader, setVisibleImageUploader] = useState(false);
  const [visibleCamera, setVisibleCamera] = useState(false);

  const webcamRef = useRef<Webcam>(null);

  /** 신분증 이미지를 서버로 전송합니다. */
  const handleUploadImage = (image: File | Blob) => {
    const formData = createFormDataWithImage(image, "image_identification");
    mutation.mutate(formData);
  };

  const handleCaptureScreenshot = () => {
    setVisibleCamera(false);
    const imageSrc = webcamRef.current.getScreenshot({
      width: 1080,
      height: 1836,
    });

    if (imageSrc) {
      const file = changeBase64ToBlob(imageSrc);
      handleUploadImage(file);
    } else {
      toast.addToast(
        "신분증 이미지를 변환하는 데에 실패하였습니다.",
        "negative",
      );
    }
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      navigateWithParams({ pathname: "/service-apply/deposit-account" });
    }
  }, [mutation.isSuccess]);

  trackPageViewMixpanel("신분증 등록");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header previous={"/service-apply/document-guide"}></Header>
      <PageContainer padding={20}>
        {visibleCamera && (
          <IdentificationCameraView
            onShooting={handleCaptureScreenshot}
            webcamRef={webcamRef}
            onClose={() => setVisibleCamera(false)}
          />
        )}

        <VStack spacing={30} center>
          <VStack spacing={4}>
            <Text typo={"subtitle-1"} bold>
              사장님의 신분증을 첨부해주세요
            </Text>
            <Text color="content-secondary">
              {
                "주민등록증 혹은 운전면허증를 첨부해주세요.\n여권과 임시 신분증은 받지 않으니 참고해주세요."
              }
            </Text>
          </VStack>

          <Image
            src={"/service/id-card.png"}
            alt={"얼리페이 신분증 예시 이미지"}
            width={240}
            height={240}
          />
        </VStack>

        <ImageUploader
          visible={visibleImageUploader}
          onClose={() => setVisibleImageUploader(false)}
          guide={"identification"}
          handleSaveImage={handleUploadImage}
          handleCamera={() => setVisibleCamera(true)}
        />
      </PageContainer>

      <ActionButton
        buttonType={"single"}
        primaryButtonLabel={"첨부하기"}
        onClickPrimaryButton={() => setVisibleImageUploader(true)}
        loading={mutation.isPending}
        primaryButtonProperty={{
          description: "신분증 인증 완료 버튼",
        }}
      />
    </>
  );
};

export default Identification;
