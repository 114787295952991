import React from "react";

import { pdfWorkerPolyfill } from "@earlypay/shared";
import { routers } from "@routes/routers";
import "@styles/global.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";

import { queryClient } from "@/apis";

function App() {
  pdfWorkerPolyfill();

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <React.Suspense fallback={<React.Fragment />}>
          <RouterProvider router={routers} />
        </React.Suspense>
        <ToastContainer transition={Slide} newestOnTop />
        <ReactQueryDevtools initialIsOpen={false} />
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
