import React from "react";

import { modalCode, trackPageViewMixpanel } from "@earlypay/shared";
import { applicationState } from "@recoil/applications/atoms";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Box,
  InfoBox,
  LottieAnimation,
  PageContainer,
  Text,
  Tooltip,
  VStack,
  useConfirmModal,
} from "@earlybird/ui";

import { Header } from "@components/layouts";

import Meta from "../../../../components/layouts/Meta";

export const Expired = () => {
  const application = useRecoilValue(applicationState);
  const confirmModal = useConfirmModal();
  const name = application.user.name;

  const handleRetryApplication = () => {
    confirmModal.openConfirmModal({
      id: modalCode.ERROR_RETRY_SERVICE_APPLY,
    });
  };

  trackPageViewMixpanel("심사 결과 | EXPIRED(만료)");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header hasLeftButton={false} />
      <PageContainer style={{ justifyContent: "between" }}>
        <VStack align={"center"} height={"100%"}>
          <Text typo={"subtitle-1"} bold center>
            {`${name} 사장님!\n5분 만에 신청하고, 전일 매출\n바로 다음날 받아보세요`}
          </Text>
          <Box width={"100%"} center>
            <LottieAnimation src={"money-rain"} width={240} height={240} />
          </Box>
          <Text color={"content-secondary"} center>
            {
              "기다리지 않고 매일 입금받는 선정산 서비스로\n매장 운영을 더 편리하게 하세요!"
            }
          </Text>
        </VStack>

        <InfoBox
          message={
            "이전에 작성해주신 신청서는 시간이 지나서\n얼리페이가 안전하게 폐기했어요. 새롭게 작성해\n주시면 신속한 진행 도와드릴게요."
          }
          state={"info"}
          style={{ marginBottom: 24 }}
        />
      </PageContainer>

      <Tooltip
        message={"평일에도 주말에도, 어제 매출 오늘 받고 싶다면?"}
        offset={10}
      >
        <ActionButton
          primaryButtonLabel={"선정산 서비스 신청하기"}
          onClickPrimaryButton={handleRetryApplication}
          primaryButtonProperty={{
            description: "만료 | 선정산 서비스 신청하기 버튼",
          }}
        />
      </Tooltip>
    </>
  );
};

export default Expired;
