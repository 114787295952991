import { sentryHook } from "@earlypay/shared/configs";
import {
  ApplicationRequests,
  BankAccountRequests,
  WithdrawalAccountArsRequests,
} from "@earlypay/shared/src/typings/apis/requests";
import {
  RecommenderCodeRequests,
  ServerError,
  modalCode,
} from "@earlypay/shared/typings";
import {
  getErrorResponse,
  handleServerErrorCode,
} from "@earlypay/shared/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useConfirmModal, useToast } from "@earlybird/ui";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import { instance } from "@/apis";
import {
  deleteImageDocuments,
  patchApplication,
  patchApplicationNew,
  patchDepositAccount,
  patchIdentification,
  patchRecommender,
  postApplicationDocument,
  postApplicationReview,
  postApplicationSubmit,
  postApplications,
  postImageDocuments,
  postVerifyBankAccount,
  postWithdrawalAccountArs,
} from "@/apis/endpoints";
import { applicationsQueryKeys } from "@/apis/hooks";

export const useAddApplication = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: () => postApplications(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      toast.addToast("서비스 신청서 생성에 실패했습니다.", "negative");
      sentryHook({
        title: "얼리페이 서비스 신청서 생성에 실패했습니다.",
        error: error,
      });

      return error;
    },
    retry: 0,
  });
};

export const useUpdateApplication = (applicationId: number) => {
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.updateApplication(applicationId).queryKey,
    mutationFn: async (payload: ApplicationRequests) => {
      await patchApplication(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      sentryHook({
        title: "얼리페이 서비스 신청서 수정에 실패하였습니다.",
        error: error,
      });

      confirmModal.openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};

/**
 * 얼리페이 서비스 신청서에 필요한 이미지 document 를 수정하는 mutation 입니다.
 */
export const useUpdateDocument = (applicationId: number) => {
  const queryClient = useQueryClient();
  const modal = useConfirmModal();

  return useMutation({
    mutationKey: applicationsQueryKeys.updateDocument(applicationId).queryKey,
    mutationFn: async (payload: FormData) => {
      await postApplicationDocument(applicationId, payload);
    },
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      sentryHook({
        title: "얼리페이 서비스 신청서 서류 제출에 실패했습니다.",
        error: error,
      });

      if (errorResponse && "code" in errorResponse) {
        switch (errorResponse.code) {
          case "invalid-format":
          case "image-too-small":
            return modal.openConfirmModal({
              id: modalCode.WARNING_INVALID_IMAGE_FILE,
            });
          default:
            return modal.openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
        }
      }

      modal.openConfirmModal({ id: modalCode.ERROR_UNKNOWN });

      return error;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    retry: 0,
  });
};

/*
 * 회수계좌의 ARS 인증 및 갱신을 하기 위한 mutation 함수입니다.
 */
export const useArsWithdrawalAccount = (applicationId: number) => {
  const queryClient = useQueryClient();
  const modal = useConfirmModal();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.arsWithdrawalAccount(applicationId).queryKey,
    mutationFn: async (payload: WithdrawalAccountArsRequests) => {
      Object.assign(instance.defaults, { timeout: 200000 });
      await postWithdrawalAccountArs(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      if (errorResponse && "code" in errorResponse) {
        switch (errorResponse.code) {
          case "duplicate-ars":
            return modal.openConfirmModal({
              id: modalCode.WARNING_DUPLICATE_ACCOUNT_ARS,
            });
          default:
            return modal.openConfirmModal({
              id: modalCode.WARNING_FAILED_WITHDRAWAL_ACCOUNT_ARS,
            });
        }
      }

      modal.openConfirmModal({
        id: modalCode.ERROR_UNKNOWN,
      });

      sentryHook({
        title: "회수계좌 ARS 인증 및 갱신에 실패하였습니다.",
        error: error,
      });
    },
    onSettled: () => {
      Object.assign(instance.defaults, { timeout: 20000 });
    },
    retry: 0,
  });
};

/**
 * 입금계좌를 수정하는 mutation 함수입니다.
 */
export const useUpdateDepositAccount = (applicationId: number) => {
  const queryClient = useQueryClient();
  const modal = useConfirmModal();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.updateDepositAccount(applicationId).queryKey,
    mutationFn: async (payload: BankAccountRequests) =>
      patchDepositAccount(applicationId, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      sentryHook({
        title: "입금계좌 정보 업데이트에 실패했습니다.",
        error: error,
      });

      if (
        errorResponse &&
        "code" in errorResponse &&
        errorResponse.code === "is-not-owner"
      ) {
        modal.openConfirmModal({
          id: modalCode.WARNING_INVALID_USER_ACCOUNT,
        });
      }
    },
    retry: 0,
  });
};

/**
 * 유저가 은행 계좌의 소유자가 맞는지 검증하는 mutation 함수입니다.
 */
export const useVerifyBankAccount = (applicationId: number) => {
  const queryClient = useQueryClient();
  const modal = useConfirmModal();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.verifyBankAccount(applicationId).queryKey,
    mutationFn: async (payload: BankAccountRequests) =>
      postVerifyBankAccount(applicationId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      sentryHook({
        title: "은행 계좌 예금주 조회에 실패하였습니다.",
        error: error,
      });

      if (
        errorResponse &&
        "code" in errorResponse &&
        errorResponse.code === "is-not-owner"
      ) {
        return modal.openConfirmModal({
          id: modalCode.WARNING_INVALID_WITHDRAWAL_ACCOUNT,
        });
      }

      modal.openConfirmModal({
        id: modalCode.ERROR_UNKNOWN,
      });
    },
    retry: 0,
  });
};

export const useUpdateIdentification = (applicationId: number) => {
  const queryClient = useQueryClient();
  const { openConfirmModal } = useConfirmModal();

  return useMutation({
    mutationFn: async (payload: FormData) => {
      await patchIdentification(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      if (errorResponse && "error" in errorResponse) {
        switch (errorResponse.error?.code) {
          case "bad-image":
          case "bad-image-orientation":
            return openConfirmModal({
              id: modalCode.WARNING_FAILED_IDENTIFICATION,
            });
          case "image-too-small":
          case "max-size-exceeded":
          case "invalid-format":
            return openConfirmModal({
              id: modalCode.WARNING_INVALID_IMAGE_FILE,
            });
          case "different-users-name":
          case "different-birthdate":
            return openConfirmModal({
              id: modalCode.WARNING_DIFFERENT_IDENTIFICATION,
            });
          case "max-retry-exceeded":
            return openConfirmModal({ id: modalCode.ERROR_MAX_RETRY_EXCEEDED });
          default:
            return openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
        }
      }

      sentryHook({
        title: "신분증 이미지 업로드에 실패하였습니다.",
        error: error,
      });

      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};

export const useUpdateImageDocument = (applicationId: number) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { openConfirmModal } = useConfirmModal();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.updateDocumentImage(applicationId).queryKey,
    mutationFn: async (payload: FormData) => {
      await postImageDocuments(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey:
          applicationsQueryKeys.fetchImageDocument(applicationId).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      toast.addToast("사진이 등록되었어요.", "success");
    },
    onError: (error: ServerError) => {
      sentryHook({
        title: "카드 단말기 이미지 등록에 실패하였습니다.",
        error: error,
      });

      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};

export const useDeleteImageDocument = (applicationId: number) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.deleteDocumentImage(applicationId).queryKey,
    mutationFn: async (imageDocumentId: number) => {
      await deleteImageDocuments(applicationId, imageDocumentId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey:
          applicationsQueryKeys.fetchImageDocument(applicationId).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
      toast.addToast("사진이 삭제되었어요.", "success");
    },
    onError: (error: ServerError) => {
      sentryHook({
        title: "카드 단말기 이미지 삭제에 실패하였습니다.",
        error: error,
      });

      toast.addToast("사진 삭제에 실패하였습니다.", "negative");
    },
    retry: 0,
  });
};

export const useReviewApplication = (applicationId: number) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const confirmModal = useConfirmModal();
  const { navigateWithParams } = useNavigateWithParams();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.reviewApplication(applicationId).queryKey,
    mutationFn: async () => {
      await postApplicationReview(applicationId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: async (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      const errorCode = handleServerErrorCode(error as AxiosError);
      if (errorCode !== "application-rejected") {
        sentryHook({
          title: "서비스 신청서 검사에 실패했습니다.",
          error: error,
        });
      }

      if (errorResponse && "error" in errorResponse) {
        switch (errorResponse.error?.code) {
          case "max-retry-exceeded":
            return navigateWithParams({
              pathname: "/service-apply/screening/credit-rejected",
            });
          default:
            return confirmModal.openConfirmModal({
              id: modalCode.ERROR_UNKNOWN,
            });
        }
      }

      confirmModal.openConfirmModal({
        id: modalCode.ERROR_UNKNOWN,
      });

      return error;
    },
    retry: 0,
  });
};

export const useUpdateApplicationNew = (applicationId: number) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationKey:
      applicationsQueryKeys.updateApplicationNew(applicationId).queryKey,
    mutationFn: async () => {
      await patchApplicationNew(applicationId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      toast.addToast("알 수 없는 오류가 발생했습니다.", "negative");

      sentryHook({
        title: "얼리페이 서비스 신청서 상태를 NEW 로 변환에 실패했습니다.",
        error: error,
      });

      return error;
    },
    retry: 0,
  });
};

export const useUpdateApplicationSubmit = (applicationId: number) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: () => postApplicationSubmit(applicationId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      toast.addToast("서비스 신청서 제출에 실패했습니다.", "negative");

      sentryHook({
        title: "얼리페이 서비스 신청서 제출에 실패했습니다.",
        error: error,
      });

      throw error;
    },
    retry: 0,
  });
};

export const useUpdateRecommender = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: async (payload: RecommenderCodeRequests) => {
      await patchRecommender(payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      toast.addToast("친구 추천 코드 업데이트에 실패했습니다.", "negative");

      sentryHook({
        title: "얼리페이 서비스 친구 추천 코드 업데이트에 실패했습니다.",
        error: error,
      });

      throw error;
    },
    retry: 0,
  });
};
