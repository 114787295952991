import React, { useEffect, useState } from "react";

import {
  BankCode,
  ServiceApplyFormValues,
  createRandomNumber,
  trackPageViewMixpanel,
  withdrawalAccountSchema,
} from "@earlypay/shared";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  ActionButton,
  ArsCertification,
  Box,
  Image,
  InfoBox,
  Input,
  PageContainer,
  Text,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import { useNavigateWithParams } from "@hooks/useNavigateWithParams";

import {
  useArsWithdrawalAccount,
  useVerifyBankAccount,
} from "@apis/hooks/applications/mutations";

import { applicationState } from "@/recoil/applications/atoms";

export const Verify = () => {
  const { navigateWithParams } = useNavigateWithParams();
  const application = useRecoilValue(applicationState);
  const [searchParams] = useSearchParams();
  const extractedAccount = searchParams.get("withdrawalAccount");
  const methods = useForm({
    resolver: yupResolver(withdrawalAccountSchema),
    defaultValues: {
      withdrawalAccount: extractedAccount
        ? extractedAccount
        : application.withdrawalAccount.account ?? "",
    },
    mode: "onChange",
  });

  const arsMutation = useArsWithdrawalAccount(application.id);
  const verifyMutation = useVerifyBankAccount(application.id);

  const [visibleModal, setVisibleModal] = useState(false);
  const [disabledArs, setDisabledArs] = useState(true);

  const isCertificatedArs = application.withdrawalAccount.isArsAuthenticated;
  const withdrawalAccount = methods.getValues("withdrawalAccount");

  const imageUrl = application.documents.imageKnbankAccount;
  const [randomCode, setRandomCode] = useState(createRandomNumber());

  /** [인증 전화 받기] 버튼 클릭 시 유효한 계좌 인증을 진행합니다.
   * 계좌 인증에 성공하면, 경남은행 ARS 인증을 진행합니다.
   */
  const onSubmit = (values: ServiceApplyFormValues) => {
    if (
      isCertificatedArs &&
      values.withdrawalAccount === application.withdrawalAccount.account
    ) {
      return navigateWithParams({
        pathname: "/service-apply/delivery-account",
      });
    }

    verifyMutation.mutate({
      bank: BankCode.BNK_KN,
      account: values.withdrawalAccount,
    });
  };

  /* 경남은행 ARS 인증을 요청합니다. */
  const handleArsWithdrawalAccount = () => {
    arsMutation.mutate({
      bank: BankCode.BNK_KN,
      account: withdrawalAccount,
      authCode: randomCode,
    });
    setVisibleModal(true);
  };

  /* 경남은행 ARS 인증을 위한 인증번호를 재요청합니다. */
  const handleRetryCertification = () => {
    setRandomCode(createRandomNumber());
    arsMutation.mutate({
      bank: BankCode.BNK_KN,
      account: withdrawalAccount,
      authCode: randomCode,
    });
  };

  /* 경남은행 ARS 인증을 진행합니다. */
  const handleCertificateAuthCode = () => {
    navigateWithParams({ pathname: "/service-apply/delivery-account" });
  };

  useEffect(() => {
    if (arsMutation.isSuccess) {
      setDisabledArs(false);
    }
  }, [arsMutation.isSuccess, arsMutation.isError]);

  useEffect(() => {
    if (verifyMutation.isSuccess) {
      handleArsWithdrawalAccount();
    }
  }, [verifyMutation.isSuccess]);

  trackPageViewMixpanel("회수계좌 ARS 인증");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Header
            previous={
              application.withdrawalAccount.account
                ? "/service-apply/deposit-account"
                : "/service-apply/withdrawal-account"
            }
          ></Header>
          <PageContainer spacing={10}>
            <Text typo={"subtitle-1"} bold>
              {"계좌번호 확인 후\n전화 인증을 진행해주세요"}
            </Text>
            <Box width={"100%"} center>
              <CustomImage url={imageUrl} width={200} />
            </Box>
            <Controller
              control={methods.control}
              name={"withdrawalAccount"}
              render={({ field }) => (
                <Input
                  type={"number"}
                  name={"withdrawalAccount"}
                  title={"계좌번호"}
                  maxLength={14}
                  required
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                />
              )}
            />
            <InfoBox
              state={"info"}
              message={
                "해당 과정은 위 계좌에서 얼리페이가 출금을 진행할 수 있도록 동의하는 과정이에요."
              }
            />
          </PageContainer>

          <ArsCertification
            visible={visibleModal}
            onClose={() => setVisibleModal(false)}
            authCode={randomCode}
            loading={false}
            disabled={disabledArs}
            handleRetryCertification={handleRetryCertification}
            handleCertificateAuthCode={handleCertificateAuthCode}
          />

          <ActionButton
            buttonType={"duo-horizontal"}
            primaryButtonLabel={isCertificatedArs ? "다음" : "인증 전화 받기"}
            disabledPrimaryButton={!methods.formState.isValid}
            secondaryButtonLabel={"다시 첨부하기"}
            onClickSecondaryButton={() =>
              navigateWithParams({
                pathname: "/service-apply/withdrawal-account",
              })
            }
            loading={verifyMutation.isPending}
            primaryButtonProperty={{
              description: isCertificatedArs
                ? "회수계좌 인증 완료 버튼"
                : "회수계좌 ARS 인증 버튼",
            }}
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default Verify;

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomImage = styled(Image)`
  box-shadow: 0 2px 8px 0 #0000001f;
`;
