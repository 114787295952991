import React, { useState } from "react";

import { ImageDocumentsTypes } from "@earlypay/shared";
import styled from "styled-components";

import { CameraIcon, XIcon } from "@earlybird/icons";
import { Icon, IconButton, Image, ImageUploader, Text } from "@earlybird/ui";

interface CardTerminalUploaderProps {
  cardTerminalImages: ImageDocumentsTypes[];
  handleSaveImage: (image: File) => void;
  handleDeleteImage: (id: number) => void;
}

export const CardTerminalUploader = ({
  cardTerminalImages,
  handleDeleteImage,
  handleSaveImage,
}: CardTerminalUploaderProps) => {
  const [visibleImageUploader, setVisibleImageUploader] = useState(false);

  return (
    <GridContainer>
      <UploadImageBox onClick={() => setVisibleImageUploader(true)}>
        <Icon size="xl" icon={CameraIcon} color={"content-secondary"} />
        <Text typo={"body-3"} bold color={"content-secondary"}>
          사진 첨부하기
        </Text>
      </UploadImageBox>

      {cardTerminalImages?.map(
        ({ id, image }: ImageDocumentsTypes, index: number) => {
          console.log("cardTerminalImages ", cardTerminalImages);
          console.log("cardTerminalImages[0] ", cardTerminalImages[0]);
          console.log("image ", image);

          return (
            <ImageBox key={index}>
              <Image
                width={"100%"}
                height={"100%"}
                url={image}
                objectFit={"cover"}
              />
              <DeleteButton onClick={() => handleDeleteImage(id)}>
                <Icon size="xs" icon={XIcon} color={"content-on-color"} />
              </DeleteButton>
            </ImageBox>
          );
        },
      )}

      <ImageUploader
        visible={visibleImageUploader}
        onClose={() => setVisibleImageUploader(false)}
        guide={"card-terminal"}
        handleSaveImage={handleSaveImage}
      />
    </GridContainer>
  );
};

export default CardTerminalUploader;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--earlybird-spacing-4);
  box-sizing: border-box;
  overflow: hidden;
`;

const UploadImageBox = styled.button`
  border-radius: var(--earlybird-radius-2xl);
  border: 1px solid var(--earlybird-color-border-primary);
  background-color: var(--earlybird-color-bg-primary);
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageBox = styled.div`
  position: relative;
  border-radius: var(--earlybird-radius-2xl);
  aspect-ratio: 1;
  display: flex;
  overflow: hidden;
`;

const DeleteButton = styled.button`
  border-radius: var(--earlybird-radius-circle);
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--earlybird-color-bg-negative);
  width: 24px;
  height: 24px;
`;
