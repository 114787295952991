import React, { useEffect } from "react";

import {
  SalesTypeCode,
  createFormDataWithImage,
  trackPageViewMixpanel,
} from "@earlypay/shared";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  PageContainer,
  Text,
  VStack,
  useConfirmModal,
  useToast,
} from "@earlybird/ui";

import CardTerminalUploader from "@components/features/ServiceApply/CardTerminalUploader";
import { Header, Meta } from "@components/layouts";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import {
  useDeleteImageDocument,
  useUpdateApplication,
  useUpdateImageDocument,
} from "@apis/hooks/applications/mutations";

import {
  applicationsQueryKeys,
  queryClient,
  useFetchCardTerminalImages,
} from "@/apis";
import { applicationState } from "@/recoil/applications/atoms";

const CardTerminal = () => {
  const confirmModal = useConfirmModal();
  const toast = useToast();
  const application = useRecoilValue(applicationState);
  const { navigateWithParams } = useNavigateWithParams();

  const updateApplicationMutation = useUpdateApplication(application.id);
  const updateImageMutation = useUpdateImageDocument(application.id);
  const deleteImageMutation = useDeleteImageDocument(application.id);
  const { data: cardTerminalImages } = useFetchCardTerminalImages(
    application.id,
  );

  const handlePressNext = () => {
    if (!cardTerminalImages?.length) {
      updateApplicationMutation.mutate({ isVanImageSkipped: true });
    }
    return navigateWithParams({ pathname: "/service-apply/terms-agreement" });
  };

  const handleSkippedCardTerminal = () => {
    confirmModal.openConfirmModal({
      id: "TEXT_SKIP_CARD_TERMINAL",
      onClickPrimaryButton: handlePressNext,
      onClickSecondaryButton: confirmModal.closeConfirmModal,
    });
  };

  const handleUploadImage = (image: File) => {
    if (cardTerminalImages?.length >= 9) {
      toast.addToast("최대 10개까지만 첨부할 수 있어요.", "warning");

      return;
    }

    const formData = createFormDataWithImage(image, "image");
    formData.append("documentType", "VAN");

    updateImageMutation.mutate(formData);
  };

  const handleDeleteImage = (id: number) => {
    deleteImageMutation.mutate(id);
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: applicationsQueryKeys.fetchImageDocument(application.id)
        .queryKey,
    });
  }, []);

  trackPageViewMixpanel("카드 단말기 등록");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header
        previous={
          application.serviceType === SalesTypeCode.CA
            ? "/service-apply/withdrawal-account"
            : "/service-apply/delivery-agency"
        }
      ></Header>
      <PageContainer spacing={16}>
        <VStack spacing={4}>
          <Text typo={"subtitle-1"} bold>
            {"매장에서 사용 중인 모든\n카드 단말기를 첨부해주세요"}
          </Text>

          <Text color="content-secondary">
            {
              "카드 매출을 미리 받기 위해, 이동식 카드 단말기와\n키오스크에 부착된 단말기 모두가 필요해요."
            }
          </Text>
        </VStack>

        <CardTerminalUploader
          cardTerminalImages={cardTerminalImages}
          handleSaveImage={handleUploadImage}
          handleDeleteImage={handleDeleteImage}
        />
      </PageContainer>

      <ActionButton
        buttonType="duo-vertical"
        primaryButtonLabel="완료"
        secondaryButtonLabel="건너뛰기"
        onClickPrimaryButton={handlePressNext}
        onClickSecondaryButton={handleSkippedCardTerminal}
        disabledPrimaryButton={!cardTerminalImages?.length}
        disabledSecondaryButton={!!cardTerminalImages?.length}
        loading={updateImageMutation.isPending}
        primaryButtonProperty={{
          description: "카드 단말기 등록 완료 버튼",
        }}
        secondaryButtonProperty={{
          description: "카드 단말기 등록 건너뛰기 버튼",
        }}
      />
    </>
  );
};

export default CardTerminal;
