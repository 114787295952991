import React, { useEffect, useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { termsCode, termsCodeTypes } from "@earlypay/shared/typings";
import {
  createFormDataWithPdf,
  getFormattedDateWithDots,
} from "@earlypay/shared/utils";
import { applicationState } from "@recoil/applications/atoms";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  AgreementDetails,
  AgreementList,
  InfoBox,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import { useReviewApplication, useUpdateDocument } from "@/apis";

export const CreditAgreement = () => {
  const application = useRecoilValue(applicationState);
  const today = getFormattedDateWithDots();
  const { navigateWithParams } = useNavigateWithParams();
  const [termsState, setTermsState] = useState("check");
  const [visibleTermsModal, setVisibleTermsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const documentMutation = useUpdateDocument(application.id);
  const reviewMutation = useReviewApplication(application.id);

  const handleSaveTerms = (file: File | Blob) => {
    setLoading(true);
    const formData = createFormDataWithPdf(file, "imageCreditRatingAgreement");
    documentMutation.mutate(formData);
  };

  /** 약관을 `확인`만 하기 위해서 클릭했을 때 호출하는 함수입니다. */
  const handleClickTerm = (code: termsCodeTypes) => {
    setTermsState("check");
    setVisibleTermsModal(true);
  };

  /** 약관을 `동의`하기 위해서 클릭했을 때 호출하는 함수입니다. */
  const handleClickAgreement = () => {
    setTermsState("agree");
    setVisibleTermsModal(true);
  };

  useEffect(() => {
    if (reviewMutation.isSuccess) {
      navigateWithParams({ pathname: "/service-apply/screening/success" });
    }

    if (reviewMutation.isError) {
      setLoading(false);
      setVisibleTermsModal(false);
    }
  }, [reviewMutation.isSuccess, reviewMutation.isError]);

  useEffect(() => {
    // 신용 정보 조회 동의가 성공적으로 진행되면, 서비스 신청서 검사를 진행합니다.
    if (documentMutation.isSuccess) {
      reviewMutation.mutate();
    }

    if (documentMutation.isError) {
      setLoading(false);
      setVisibleTermsModal(false);
    }
  }, [documentMutation.isSuccess, documentMutation.isError]);

  trackPageViewMixpanel("신용 정보 조회");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header hasLeftButton={false}></Header>
      <PageContainer spacing={16}>
        <Text typo={"subtitle-1"} bold>
          {"선정산을 받을 수 있는 사장님인지\n확인하기 위해 동의가 필요해요"}
        </Text>

        <VStack spacing={10}>
          <AgreementList
            title={"(주)얼리페이대부"}
            terms={[termsCode.CD01]}
            handleClickTerm={handleClickTerm}
          />
          <InfoBox
            state={"info"}
            message={
              "단순 신용 정보 조회로 신용도에 아무런 영향을 주지 않으니 안심하세요. 또한 규정을 철저히 지키며 안전하게 조회하니 개인정보 유출에 대한 걱정은 하지 않으셔도 돼요."
            }
          />
        </VStack>

        <AgreementDetails
          state={termsState}
          visible={visibleTermsModal}
          onClose={() => setVisibleTermsModal(false)}
          terms={[termsCode.CD01]}
          handleSaveTerms={handleSaveTerms}
          username={application.user.name}
          date={today}
        />
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"모두 동의하기"}
        onClickPrimaryButton={handleClickAgreement}
        loading={loading}
        primaryButtonProperty={{
          description: "신용 정보 조회 동의 버튼",
        }}
      />
    </>
  );
};

export default CreditAgreement;
