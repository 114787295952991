export const guideData = [
  {
    subtitle: "사장님 명의의",
    title: "신분증",
    image: "/service/id-card-gray.png",
  },
  {
    subtitle: "사장님 명의로된",
    title: "경남은행 예금통장",
    image: "/service/bankbook.png",
  },
  {
    subtitle: "선정산 받을",
    title: "배달앱 계정",
    image: "/service/user-account.png",
  },
  {
    subtitle: "이용 중인",
    title: "배달 대행사 연락처",
    image: "/service/telephone-book.png",
  },
  {
    subtitle: "사용 중인",
    title: "카드 단말기 사진",
    image: "/service/card-terminal.png",
  },
];
