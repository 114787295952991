import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { applicationState } from "@recoil/applications/atoms";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Box,
  LottieAnimation,
  PageContainer,
  Text,
  Tooltip,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

export const Success = () => {
  const navigate = useNavigate();
  const application = useRecoilValue(applicationState);
  const name = application.user.name;

  const handleGoToNextPage = () => {
    navigate("/service-apply/document-guide");
  };

  trackPageViewMixpanel("신청서 심사 통과");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header hasLeftButton={false} />
      <PageContainer align={"center"} spacing={20}>
        <Text typo={"subtitle-1"} bold center>
          {`축하드려요, ${name} 사장님!\n선정산 이용이 가능해요`}
        </Text>

        <Box center width={"100%"}>
          <LottieAnimation src={"hand-clap"} width={360} height={146} />
        </Box>

        <Text color={"content-secondary"} center>
          {
            "영업일 기준 2일 내로 서비스 이용에 필요한\n서류 안내 전화를 드릴 예정이에요.\n1522-6911의 연락을 꼭 받아주세요."
          }
        </Text>
      </PageContainer>

      <Tooltip message={"기다리지 않고 하루빨리 선정산 받으려면"} offset={10}>
        <ActionButton
          primaryButtonLabel={"지금 필요 서류 등록하기"}
          onClickPrimaryButton={handleGoToNextPage}
          primaryButtonProperty={{
            description: "매출 연동 중 | 지금 필요 서류 등록하기 버튼",
          }}
        />
      </Tooltip>
    </>
  );
};

export default Success;
