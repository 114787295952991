import React, { useLayoutEffect } from "react";

import {
  ApplicationStatusCode,
  changeServiceApplyCodeToPath,
} from "@earlypay/shared";
import { applicationState } from "@recoil/applications/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useRedirectUsers } from "@apis/hooks/users";

/**
 * 서비스 신청서 심사 결과 페이지를 가드하는 Route 컴포넌트입니다.
 */
const ProtectedScreeningRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const application = useRecoilValue(applicationState);
  const status = application.status; // 서비스 신청서 상태
  const currentStage = application.stage; // 서비스 신청서 플로우 단계
  const currentPath = changeServiceApplyCodeToPath(currentStage);
  const mutation = useRedirectUsers(application.user.id);

  const checkScreeningPage = () => {
    // submit 페이지로 들어왔을 때, 신청서 상태가 NEW 인 경우는 매출 유형 페이지로 되돌아 갑니다.
    if (
      location.pathname.includes("submit") &&
      application.status === ApplicationStatusCode.NEW
    ) {
      mutation.mutate("/service-apply/sales-type");
    }

    if (
      location.pathname.includes("submit") &&
      status === ApplicationStatusCode.WAITING_REVIEW
    ) {
      return;
    }

    if (
      location.pathname.includes("incomplete") &&
      status === ApplicationStatusCode.NEED_COMPLEMENT
    ) {
      return;
    }

    if (
      location.pathname.includes("restart") &&
      status === ApplicationStatusCode.CANCELLED
    ) {
      return;
    }

    if (
      location.pathname.includes("expired") &&
      status === ApplicationStatusCode.EXPIRED
    ) {
      return;
    }

    if (
      (location.pathname.includes("credit-rejected") ||
        location.pathname.includes("retry")) &&
      status === ApplicationStatusCode.REJECTED_CREDIT
    ) {
      const now = new Date();
      const past = new Date(application.statusChangedAt);
      const timeDiff = now.getTime() - past.getTime();
      const hoursPassed = timeDiff / (1000 * 60 * 60);

      if (hoursPassed >= 24) {
        return navigate("/service-apply/screening/retry", {
          state: { from: location },
        });
      }

      return;
    }

    if (
      location.pathname.includes("admin-rejected") &&
      status === ApplicationStatusCode.REJECTED_OPERATION
    ) {
      return;
    }

    if (
      location.pathname.includes("screening/success") &&
      status === ApplicationStatusCode.IN_PROGRESS
    ) {
      return;
    }

    navigate(`/service-apply/${currentPath}`);
  };

  useLayoutEffect(() => {
    if (application.id) {
      checkScreeningPage();
    }
  }, [application, navigate]);

  return <>{children}</>;
};

export default ProtectedScreeningRoute;
