import {
  AdminRejected,
  CardTerminal,
  CreditAgreement,
  CreditRejected,
  DeliveryAccount,
  DeliveryAgency,
  DepositAccount,
  DocumentGuide,
  Expired,
  Gateway,
  Identification,
  Incomplete,
  Restart,
  Retry,
  Submit,
  Success,
  TermsAgreement,
  WithdrawalAccount,
  WithdrawalAccountVerify,
} from "@pages/serviceApply";
import ProtectedScreeningRoute from "@routes/utils/ProtectedScreeningRoute";

export const serviceApplyRouters = [
  // ---------------- 페이 ----------------
  // `페이`에 해당하는 서비스 신청 단계로 접속했을 경우, 404 페이지가 아니라 리디렉트 진행할 수 있도록 path 만 부여합니다.
  {
    path: "intro",
  },
  {
    path: "sales-type",
  },
  {
    path: "auth",
  },
  {
    path: "license-verify",
  },
  {
    path: "service-agreement",
  },
  // ---------------- 대부 ----------------
  {
    index: true,
    Component: Gateway,
  },
  {
    path: "gateway",
    Component: Gateway,
  },
  {
    path: "credit-agreement",
    Component: CreditAgreement,
  },
  {
    path: "screening",
    children: [
      {
        path: "success",
        element: (
          <ProtectedScreeningRoute>
            <Success />
          </ProtectedScreeningRoute>
        ),
      },
      {
        path: "restart",
        element: (
          <ProtectedScreeningRoute>
            <Restart />
          </ProtectedScreeningRoute>
        ),
      },
      {
        path: "incomplete",
        element: (
          <ProtectedScreeningRoute>
            <Incomplete />
          </ProtectedScreeningRoute>
        ),
      },
      {
        path: "expired",
        element: (
          <ProtectedScreeningRoute>
            <Expired />
          </ProtectedScreeningRoute>
        ),
      },
      {
        path: "admin-rejected",
        element: (
          <ProtectedScreeningRoute>
            <AdminRejected />
          </ProtectedScreeningRoute>
        ),
      },
      {
        path: "credit-rejected",
        element: (
          <ProtectedScreeningRoute>
            <CreditRejected />
          </ProtectedScreeningRoute>
        ),
      },
      {
        path: "retry",
        element: (
          <ProtectedScreeningRoute>
            <Retry />
          </ProtectedScreeningRoute>
        ),
      },
    ],
  },
  {
    path: "document-guide",
    Component: DocumentGuide,
  },
  {
    path: "identification",
    Component: Identification,
  },
  {
    path: "deposit-account",
    Component: DepositAccount,
  },
  {
    path: "withdrawal-account",
    children: [
      { index: true, Component: WithdrawalAccount },
      { path: "verify", Component: WithdrawalAccountVerify },
    ],
  },
  {
    path: "delivery-account",
    children: [
      {
        index: true,
        Component: DeliveryAccount,
      },
    ],
  },
  {
    path: "delivery-agency",
    Component: DeliveryAgency,
  },
  {
    path: "card-terminal",
    Component: CardTerminal,
  },
  {
    path: "terms-agreement",
    Component: TermsAgreement,
  },
  {
    path: "submit",
    element: (
      <ProtectedScreeningRoute>
        <Submit />
      </ProtectedScreeningRoute>
    ),
  },
];
