import PublicRouteGuard from "../guards/PublicRouteGuard";
import React from "react";
import { SystemError } from "@pages/errors";
import Landing from "@pages/Landing";

export const publicRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <PublicRouteGuard />,
  children: [
    {
      path: "landing",
      element: <Landing />,
    },
  ],
};
