import React, { useEffect, useState } from "react";

import {
  PageViewGA,
  bootChannelIO,
  getCookie,
  initKakao,
  initMixpanel,
  initSentry,
  logWelcomeMessage,
  setMixpanelUser,
  setSentryUser,
  trackPageViewMixpanel,
  updateChannelIOUser,
} from "@earlypay/shared";
import { applicationState } from "@recoil/applications/atoms";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { useFetchApplication } from "@apis/hooks";

const InitializeServiceRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const accessToken = getCookie("earlypay-token");
  const { data: application } = useFetchApplication();
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);
  const setApplication = useSetRecoilState(applicationState);

  useEffect(() => {
    // initialize service
    initKakao();
    initMixpanel();
    initSentry();
    bootChannelIO();

    logWelcomeMessage();

    setIsInitialized(true);
  }, []);

  useEffect(() => {
    // tracking event
    if (isInitialized) {
      PageViewGA(location.pathname + location.search);
    }

    // update user data
    if (accessToken && application?.id) {
      setApplication(application);
      setMixpanelUser(application);
      updateChannelIOUser(application);
      setSentryUser(application);
    }
  }, [application, isInitialized, location]);

  if (!application?.id && accessToken) {
    return null;
  }

  return <>{children}</>;
};

export default InitializeServiceRoute;
