import { QueryCache, QueryClient } from "@tanstack/react-query";
import { ServerError } from "@shared/typings";
//import { useToast } from "@earlybird/ui";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error: ServerError, query) => {
      //const { addToast } = useToast();

      console.error(error);

      if (query.state.data !== undefined) {
        // addToast(
        //   "알 수 없는 오류 입니다. 고객센터에 문의해 주세요",
        //   "negative"
        // );
      }
    },
  }),
});
