import React, { useLayoutEffect } from "react";

import {
  ApplicationStatusCode,
  SalesTypeCode,
  ServiceApplyCode,
  ServiceApplyIndex,
} from "@earlypay/shared/typings";
import {
  changePathToServiceApplyCode,
  changeServiceApplyCodeToPath,
  checkEpayloanStage,
} from "@earlypay/shared/utils";
import { applicationState } from "@recoil/applications/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import { useRedirectUsers } from "@apis/hooks/users";

const ProtectedServiceApplyRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const application = useRecoilValue(applicationState);
  const stage = application.stage; // 서비스 신청서 플로우 단계

  const mutation = useRedirectUsers(application.user.id);

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const currentServiceApplyPath =
    pathSegments.includes("service-apply") && pathSegments.length
      ? pathSegments[1]
      : undefined;

  const handleGoToEarlypayService = (path: string) => {
    const code = changePathToServiceApplyCode(path);
    const isEpayloan = checkEpayloanStage(code);

    if (
      !isEpayloan &&
      (application.status === ApplicationStatusCode.APPROVED ||
        application.status === ApplicationStatusCode.NEW ||
        application.status === ApplicationStatusCode.IN_PROGRESS)
    ) {
      mutation.mutate(`/service-apply/${path}`);
    }
  };

  const handleCheckStage = (wantedStage: number, currentStage: number) => {
    const currentPath = changeServiceApplyCodeToPath(stage);
    // 신분증 인증을 이미 진행한 경우, 신분증 인증 단계를 건너뜁니다.
    if (
      ServiceApplyIndex[ServiceApplyCode.ID_CARD] < currentStage &&
      location.pathname.includes("identification")
    ) {
      return navigate(`/service-apply/${currentPath}`);
    }

    // 서비스 신청서 심사가 이미 진행되었는데, 신청서 상태가 NEW 인 경우는 매출 유형 페이지로 되돌아갈 수 있도록 리디렉트를 제거합니다.
    if (
      application.status === ApplicationStatusCode.NEW &&
      ServiceApplyIndex[ServiceApplyCode.ID_CARD] <= currentStage &&
      location.pathname.includes("sales-type")
    ) {
      return;
    }

    // 취소 상태일 경우, 취소 페이지로 리디렉트 합니다.
    if (
      location.pathname.includes("screening") &&
      application.status !== ApplicationStatusCode.NEW &&
      application.status !== ApplicationStatusCode.APPROVED
    ) {
      return;
    }

    // 신용 정보 조회 & 신청서 심사 단계를 이미 진행한 경우, 해당 단계를 건너뜁니다.
    if (
      application.status !== ApplicationStatusCode.NEW &&
      ServiceApplyIndex[ServiceApplyCode.SCREENING] < currentStage &&
      location.pathname.includes("credit-agreement")
    ) {
      return navigate(`/service-apply/document-guide`);
    }

    if (application.status === ApplicationStatusCode.WAITING_REVIEW) {
      return;
    }

    if (
      location.pathname === "/" ||
      location.pathname === "/service-apply" ||
      location.pathname === "/service-apply/"
    ) {
      return navigate(`/service-apply/${currentPath}`);
    }

    // 이미 진행한 단계 또는 현재 단계로 이동하는 것이므로 페이지 이동을 허용합니다
    if (wantedStage <= currentStage) {
      return;
    }

    // 진행하지 않은 단계로 이동하는 것이므로 페이지 이동을 허용하지 않고, 가장 최근 단계로 강제 리디렉트 합니다.
    if (wantedStage > currentStage) {
      return navigate(`/service-apply/${currentPath}`);
    }
  };

  const handleCheckSalesType = () => {
    switch (application.serviceType) {
      case SalesTypeCode.AL:
        return;
      case SalesTypeCode.CA:
        if (
          location.pathname.includes("delivery-account") ||
          location.pathname.includes("delivery-agency")
        ) {
          return navigate(`/service-apply/card-terminal`);
        }
        return;
      case SalesTypeCode.DE:
        if (location.pathname.includes("card-terminal")) {
          return navigate(`/service-apply/terms-agreement`);
        }
        return;
      default:
        return;
    }
  };

  useLayoutEffect(() => {
    if (application.id) {
      const wantedCode = changePathToServiceApplyCode(currentServiceApplyPath);
      const wantedStageIndex = ServiceApplyIndex[wantedCode];
      const currentStageIndex = ServiceApplyIndex[stage];

      // 현재 pathname 이 얼리페이 대부가 아니라 `얼리페이`에 해당하는 단계이면, earlypay.kr 로 리디렉트를 진행합니다.
      currentServiceApplyPath &&
        handleGoToEarlypayService(currentServiceApplyPath);

      /** 서비스 신청서 `단계`에 따라 페이지 이동 여부를 결정합니다. */
      handleCheckStage(wantedStageIndex, currentStageIndex);

      // 매출 유형 선택에 따라 배달앱, 카드 단말기 등록 여부를 결정합니다.
      handleCheckSalesType();
    }
  }, [application, location]);

  return <>{children}</>;
};

export default ProtectedServiceApplyRoute;
