import React, { useEffect, useState } from "react";

import {
  SentryHookTypes,
  createFormDataWithPdf,
  getFormattedDateWithDots,
  handleServerErrorCode,
  handleServerErrorMessage,
  trackPageViewMixpanel,
} from "@earlypay/shared";
import {
  SalesTypeCode,
  termsCode,
  termsCodeTypes,
} from "@earlypay/shared/typings";
import * as Sentry from "@sentry/react";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  AgreementDetails,
  AgreementList,
  PageContainer,
  Text,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import { useUpdateApplicationSubmit, useUpdateDocument } from "@/apis";
import { applicationState } from "@/recoil/applications/atoms";

export const TermsAgreement = () => {
  const application = useRecoilValue(applicationState);
  const [loading, setLoading] = useState(false);
  const today = getFormattedDateWithDots();

  const [termsState, setTermsState] = useState("check");
  const [visibleTermsModal, setVisibleTermsModal] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState([termsCode.EL01]);

  const mutation = useUpdateDocument(application.id);
  const submitMutation = useUpdateApplicationSubmit(application.id);

  const epayloanTermsList = [
    termsCode.EL01,
    termsCode.EL02,
    termsCode.EL03,
    termsCode.EL04,
    termsCode.EL05,
    termsCode.EL06,
    termsCode.EL07,
  ];
  const hanacapitalTermsList = [termsCode.HC01, termsCode.HC02, termsCode.HC03];

  const handleSaveTerms = (file: File | Blob) => {
    setLoading(true);
    const formData = createFormDataWithPdf(file, "imageLoanServiceAgreement");
    mutation.mutate(formData);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      submitMutation.mutate(); // 서비스 신청서를 제출합니다.
    }
  }, [mutation.isSuccess]);

  /** 약관을 `확인`만 하기 위해서 클릭했을 때 호출하는 함수입니다. */
  const handleClickTerm = (code: termsCodeTypes) => {
    setSelectedTerms([code]);
    setTermsState("check");
    setVisibleTermsModal(true);
  };

  /** 약관을 `동의`하기 위해서 클릭했을 때 호출하는 함수입니다. */
  const handleClickAgreement = () => {
    setSelectedTerms([...epayloanTermsList, ...hanacapitalTermsList]);
    setTermsState("agree");
    setVisibleTermsModal(true);
  };

  trackPageViewMixpanel("대부/하나캐피탈 이용 약관 동의");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header
        previous={
          application.serviceType === SalesTypeCode.DE
            ? "/service-apply/delivery-agency"
            : "/service-apply/card-terminal"
        }
      ></Header>
      <PageContainer spacing={16}>
        <Text typo={"subtitle-1"} bold>
          {"마지막으로 동의해주시면\n서비스 신청이 완료돼요"}
        </Text>

        <AgreementList
          title={"(주)얼리페이대부"}
          terms={epayloanTermsList}
          handleClickTerm={handleClickTerm}
        />
        <AgreementList
          title={"하나캐피탈(주)"}
          terms={hanacapitalTermsList}
          handleClickTerm={handleClickTerm}
        />

        <AgreementDetails
          state={termsState}
          visible={visibleTermsModal}
          onClose={() => setVisibleTermsModal(false)}
          terms={selectedTerms}
          handleSaveTerms={handleSaveTerms}
          username={application.user.name}
          date={today}
        />
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"모두 동의하기"}
        onClickPrimaryButton={handleClickAgreement}
        loading={loading}
        primaryButtonProperty={{
          description: "대부/하나캐피탈 약관 동의 버튼",
        }}
      />
    </>
  );
};

export default TermsAgreement;
