import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { PageHeader, PageHeaderProps } from "@earlybird/ui";

import SettingModal from "@components/layouts/SettingModal";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

export const Header = ({ previous, ...rest }: PageHeaderProps) => {
  const navigate = useNavigate();
  const { navigateWithParams } = useNavigateWithParams();
  const [visibleSetting, setVisibleSetting] = useState(false);

  const handleClickLeftButton = () => {
    if (!previous && !handleClickLeftButton) {
      navigate(-1);
    }

    navigateWithParams({ pathname: previous });
  };

  return (
    <>
      <PageHeader
        handleClickLeftButton={handleClickLeftButton}
        handleClickRightButton={() => setVisibleSetting(true)}
        {...rest}
      ></PageHeader>

      <SettingModal
        visible={visibleSetting}
        onClose={() => setVisibleSetting(false)}
      />
    </>
  );
};

export default Header;
