import {
  ApplicationRequests,
  BankAccountRequests,
  RecommenderCodeRequests,
  WithdrawalAccountArsRequests,
} from "@shared/typings/apis/requests";
import { AxiosResponse } from "axios";

import { instance } from "@/apis/instance";

/**
 * 얼리페이 신청서를 수정하는 API 함수입니다.
 * @param applicationId
 * @param payload
 */
export const patchApplication = async <T>(
  applicationId: number,
  payload: ApplicationRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/applications/${applicationId}`,
    data: payload,
  });

  return data;
};

/*
 * 유저의 신청서 상태를 NEW 로 변환하는 API 함수입니다.
 */
export const patchApplicationNew = async (applicationId: number) => {
  const { data } = await instance({
    method: "patch",
    url: `v2/applications/${applicationId}/new`,
  });

  return data;
};

/**
 * 얼리페이 신청서 작성 단계에서 필요한 문서들을 저장하는 API 함수입니다.
 * @param applicationId
 * @param payload
 */
export const postApplicationDocument = async <T>(
  applicationId: number,
  payload: FormData,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/document`,
    data: payload,
  });

  return data;
};

export const getApplication = async <T>(): Promise<T | null> => {
  const { data }: AxiosResponse<T> = await instance({
    method: "get",
    url: `/v2/applications/me`,
  });

  return data;
};

/**
 * 회수 계좌의 ARS 인증 및 갱신 API 호출 함수입니다.
 * @param applicationId
 * @param payload
 */
export const postWithdrawalAccountArs = async <T>(
  applicationId: number,
  payload: WithdrawalAccountArsRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/bank/withdrawal/ars`,
    data: {
      ...payload,
    },
  });

  return data;
};

/**
 * 지급 계좌를 수정하는 API 함수입니다.
 * @param applicationId
 * @param payload
 */
export const patchDepositAccount = async <T>(
  applicationId: number,
  payload: BankAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/applications/${applicationId}/bank/deposit`,
    data: {
      ...payload,
    },
  });

  return data;
};

/**
 * 유저가 은행 계좌의 소유자가 맞는지 검증하는 API 함수입니다.
 * @param applicationId
 * @param payload
 */
export const postVerifyBankAccount = async <T>(
  applicationId: number,
  payload: BankAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/bank/verify`,
    data: {
      ...payload,
    },
  });

  return data;
};

/**
 * 신분증 이미지를 전달하여 OCR 인증을 진행하는 API 호출 함수입니다.
 * @param payload
 * @param applicationId
 */
export const patchIdentification = async (
  applicationId: number,
  payload: FormData,
) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/applications/${applicationId}/document/identification`,
    data: payload,
  });

  return data;
};

/**
 * 신청서의 이미지 서류를 조회하는 API 함수입니다.
 * @param applicationId
 */
export const getImageDocument = async <T>(
  applicationId: number,
): Promise<T | null> => {
  const { data }: AxiosResponse<T> = await instance({
    method: "get",
    url: `/v2/applications/${applicationId}/image-documents`,
  });

  return data;
};

/**
 * 신청서의 이미지 서류를 제출하는 API 함수입니다.
 * @param applicationId
 * @param payload
 */
export const postImageDocuments = async <T>(
  applicationId: number,
  payload: FormData,
): Promise<T | null> => {
  const { data }: AxiosResponse<T> = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/image-documents`,
    data: payload,
  });

  return data;
};

/**
 * 신청서의 이미지 서류를 삭제하는 API 함수입니다.
 * @param applicationId
 * @param ImageDocumentId
 */
export const deleteImageDocuments = async <T>(
  applicationId: number,
  ImageDocumentId: number,
): Promise<T | null> => {
  const { data }: AxiosResponse<T> = await instance({
    method: "delete",
    url: `/v2/applications/${applicationId}/image-documents/${ImageDocumentId}`,
  });

  return data;
};

/*
 * 유저의 신청서 상태를 새롭게 추가하는 API 함수입니다.
 */
export const postApplications = async () => {
  const { data } = await instance({
    method: "post",
    url: `v2/applications`,
  });

  return data;
};

/*
 * 서비스 신청서 검사를 진행하는 API 함수입니다.
 */
export const postApplicationReview = async (applicationId: number) => {
  const { data } = await instance({
    method: "post",
    url: `v2/applications/${applicationId}/review`,
  });

  return data;
};

/*
 * 얼리페이 서비스 신청서를 제출하는 API 함수입니다.
 */
export const postApplicationSubmit = async (applicationId: number) => {
  const { data } = await instance({
    method: "post",
    url: `v2/applications/${applicationId}/submit`,
  });

  return data;
};

/*
 * 추천인을 등록하는 API 함수입니다.
 */
export const patchRecommender = async (payload: RecommenderCodeRequests) => {
  const { data } = await instance({
    method: "patch",
    url: `v1/applications/regist-recommender/`,
    data: {
      ...payload,
    },
  });

  return data;
};
