import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";

import {
  ActionButton,
  Box,
  InfoBox,
  LottieAnimation,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header } from "@components/layouts";

import { useLogin } from "@hooks/useLogin";

import Meta from "../../../../components/layouts/Meta";

export const CreditRejected = () => {
  const { handleLogout } = useLogin();

  /** 로그아웃 처리 후, 랜딩 페이지로 이동합니다. */
  const handleRestartService = () => {
    handleLogout();
  };

  trackPageViewMixpanel("심사 결과 | REJECTED_CREDIT(신용 거절 24시간 전)");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header hasLeftButton={false} />
      <PageContainer style={{ justifyContent: "space-between" }}>
        <VStack center align={"center"}>
          <Text typo={"subtitle-1"} bold center>
            {`죄송합니다, 안타깝게도\n현재는 서비스 이용이 어려워요`}
          </Text>
          <Box width={"100%"} center>
            <LottieAnimation src={"warning"} width={240} height={240} />
          </Box>
          <Text color={"content-secondary"} center>
            {
              "신용점수, 연체이력 등 다양한 정보를 통해\n심사를 진행하므로 개인별 구체적 사유를 말씀드리기\n어려울 수 있다는 점 양해 부탁드려요."
            }
          </Text>
        </VStack>

        <InfoBox
          title={
            "신용 관련하여 긍정적인 변화가 있을 때 다시 신청해주세요. 얼리페이가 기다리고 있을게요!"
          }
          state={"success"}
        />
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"나중에 다시 오기"}
        onClickPrimaryButton={handleRestartService}
        primaryButtonProperty={{
          description: "신용 거절 24시간 전 | 나중에 다시 오기 버튼",
        }}
      />
    </>
  );
};

export default CreditRejected;
