import React from "react";

import {
  ServiceApplyCode,
  ServiceApplyIndex,
  trackPageViewMixpanel,
} from "@earlypay/shared";
import { guideData } from "@pages/serviceApply/DocumentGuide/dataSetArray";
import { applicationState } from "@recoil/applications/atoms";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  ActionButton,
  HStack,
  Image,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

export const DocumentGuide: React.FC = () => {
  const application = useRecoilValue(applicationState);
  const { navigateWithParams } = useNavigateWithParams();
  const isIdentification =
    ServiceApplyIndex[ServiceApplyCode.ID_CARD] <
    ServiceApplyIndex[application.stage];

  const handleGoToNextStage = () => {
    if (isIdentification) {
      return navigateWithParams({ pathname: "/service-apply/deposit-account" });
    }
    navigateWithParams({ pathname: "/service-apply/identification" });
  };

  trackPageViewMixpanel("매출 연동 서류 안내");

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} />
      <Header hasLeftButton={false}></Header>
      <PageContainer spacing={16}>
        <VStack spacing={3}>
          <Text typo={"subtitle-1"} bold>
            미리 준비해주세요
          </Text>
          <Text color={"content-secondary"}>
            {
              "혹시라도 등록 중 어려움이 발생하면 언제든\n고객센터로 문의해주세요."
            }
          </Text>
        </VStack>

        <VStack spacing={3}>
          {guideData.map(({ subtitle, title, image }, index) => (
            <HStack key={index} spacing={4} align={"center"}>
              <Image src={image} width={64} height={64} />
              <CardTitle>
                <Text typo={"body-3"} color={"content-secondary"}>
                  {subtitle}
                </Text>
                <Text bold color={"content-secondary"}>
                  {title}
                </Text>
              </CardTitle>
            </HStack>
          ))}
        </VStack>
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"등록 시작하기"}
        onClickPrimaryButton={handleGoToNextStage}
        primaryButtonProperty={{
          description: "매출 연동 서류 안내 다음 버튼",
        }}
      />
    </>
  );
};

export default DocumentGuide;

const CardImage = styled.img`
  display: flex;
  width: 64px;
  height: 64px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
