import React from "react";
import PublicLayout from "@routes/layouts/PublicLayout";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";

const PublicRouteGuard: React.FC = () => {
  return (
    <InitializeServiceRoute>
      <PublicLayout />
    </InitializeServiceRoute>
  );
};

export default PublicRouteGuard;
