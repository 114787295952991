import { useMutation, useQueryClient } from "@tanstack/react-query";
import { modalCode, ServerError } from "@earlypay/shared/typings";
import { sentryHook } from "@earlypay/shared/configs";
import { useConfirmModal } from "@earlybird/ui";
import { DeliveryAgencyRequests } from "@earlypay/shared/src/typings/apis/requests";
import { deliveryQueryKeys } from "./queryKeys";
import { putDeliveryAgents } from "@apis/endpoints/delivery";
import { applicationsQueryKeys } from "@/apis";

export const useUpdateDeliveryAgents = (applicationId: number) => {
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();

  return useMutation({
    mutationKey: deliveryQueryKeys.updateDeliveryAgents(applicationId).queryKey,
    mutationFn: async (payload: DeliveryAgencyRequests[]) => {
      await putDeliveryAgents(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: deliveryQueryKeys.fetchDeliveryAgents(applicationId).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      sentryHook({
        title: "배달 대행 업체 등록 및 수정에 실패하였습니다.",
        error: error,
      });

      confirmModal.openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};
